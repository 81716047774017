import { Button } from "./components";
import Footer from "./components/Footer";

const desktopblock = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        zIndex: 100,
        pointerEvents: "none",
        backgroundColor: "#f4e3c9",
      }}
    >
      <img
        style={{
          position: "absolute",
          top: "15%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
        src="png/logo.png"
        alt="title"
      />

      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
          zIndex: 2,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "800",
            color: "bLACK",
          }}
        >
          THIS EXPERIENCE IS ONLY AVAILABLE ON MOBILE
          <br />
          PLEASE SCAN THE QR CODE TO ACCESS
        </p>

        <img src="qrcode/Coach_APW_QR.jpg" alt="qrcode" />

        <span
        style={{}}
            onClick={() => {
              window.open(
                "https://malaysia.coach.com/coach-play-apw",
                "_blank"
              );
            }}
          >
        <Button
          style={{ pointerEvents: "auto", fontSize: "1rem", width: "15rem", height: "4rem" }}
        >
          LEARN MORE
        </Button>
        </span>

      </div>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            position: "absolute",
            width: "30%",
            maxWidth: "25rem",
            top: "20%",
            left: "15%",
            transform: "translate(-50%, -50%)",
          }}
          src="png/C1.png"
          alt="c1"
        />
        <img
          style={{
            position: "absolute",
            width: "30%",
            maxWidth: "25rem",
            top: "37%",
            right: "15%",
            transform: "translate(50%, -50%)",
          }}
          src="png/C2.png"
          alt="c2"
        />
        <img
          style={{
            position: "absolute",
            width: "30%",
            maxWidth: "25rem",
            bottom: "20%",
            left: "25%",
            transform: "translate(-50%, 50%) rotate(10deg)",
          }}
          src="png/C3.png"
          alt="c3"
        />
        <img
          style={{
            position: "absolute",
            width: "30%",
            maxWidth: "25rem",
            bottom: "12%",
            right: "12%",
            transform: "translate(50%, 50%)",
          }}
          src="png/tile.png"
          alt="tile"
        />
      </div>

      <Footer style={{ fontSize: "1rem" }} />
    </div>
  );
};

export default desktopblock;
