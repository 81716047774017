import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SuperfanProvider } from "../src/superfancontext";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoadingAnimation from "./loading.json";
import Loading from "./pages/components/Loading";

const root = ReactDOM.createRoot(document.getElementById("root"));
let collection = "coach-my-apw";

const loadingOption = {
  loop: true,
  autoplay: true,
  animationData: LoadingAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const preloadLottieAnimation = () => {
  return new Promise((resolve) => {
    // Just resolve immediately since the animation data is already imported
    console.log("Lottie animation loaded");
    resolve(LoadingAnimation);
  });
};

const preloadFonts = async () => {
  const font1 = new FontFace("Impact", "url(/font/impact/impact.ttf)");
  const font2 = new FontFace(
    "Helvetica",
    "url(/font/helvetica/HelveticaNeueLTPro-Roman.otf)"
  );
  const font3 = new FontFace(
    "HelveticaBtn",
    "url(/font/helvetica/HelveticaNeueLTPro-Ex.otf)"
  );

  return Promise.all([font1.load(), font2.load(), font3.load()])
    .then((loadedFonts) => {
      console.log("Fonts loaded");
      loadedFonts.forEach((font) => document.fonts.add(font));
    })
    .catch((error) => {
      console.error("Error loading fonts", error);
    });
};

const preloadImages = () => {
  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        console.log(`Image loaded: ${src}`);
        resolve();
      };
      img.onerror = (error) => {
        console.error(`Error loading image: ${src}`, error);
        reject(error);
      };
    });
  };

  return Promise.all(
    [
      "webp/block.webp",
      "webp/box.webp",
      "webp/btn01_1.webp",
      "webp/btn2.webp",
      "webp/C1.webp",
      "webp/C1_fill.webp",
      "webp/C2.webp",
      "webp/C2_fill.webp",
      "webp/C3.webp",
      "webp/C3_fill.webp",
      "webp/claw_machine.webp",
      "webp/discount.webp",
      "webp/ExclusivePass.webp",
      "webp/GeneralPass.webp",
      "webp/logo.webp",
      "webp/photobooth.webp",
      "webp/poster.webp",
      "webp/score.webp",
      "webp/stamp.webp",
      "webp/ticket.webp",
      "webp/tile.webp",
      "gif/Stamp_Turn_3.gif",
    ].map(preloadImage)
  );
};

// const preloadAssets = () => {
//   return Promise.all([preloadFonts(), preloadImages()]);
// };

// const preloadLottie = () => {
//   return Promise.all([preloadLottieAnimation()]);
// }

const Root = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDuration, setLoadingDuration] = useState(false);

  useEffect(() => {
    const preloadAssets = async () => {
      try {
        await Promise.all([preloadLottieAnimation(), preloadFonts(), preloadImages()]);
        console.log("Assets loaded");
      } catch (error) {
        console.error("Error loading assets:", error);
      }
    };

    const handleLoading = async () => {
      await preloadAssets();
      setLoadingDuration(true); // Allow for a minimum display duration

      // Wait at least 2 seconds before changing loading state
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };

    handleLoading();
  }, []);

  // Show a loading spinner or message while fonts are loading
  if (isLoading || !loadingDuration) {
    return <Loading />;
  }

  return <App />;
};

root.render(
  <BrowserRouter>
    <SuperfanProvider collection={collection}>
      <Root />
    </SuperfanProvider>
  </BrowserRouter>
);

reportWebVitals();
