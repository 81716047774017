import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './lotties/data.json';

const StampAnimation = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      renderer: 'svg',
      loop: false,
      autoplay: true,
    });

    // Play frames 1 to 109
    animation.playSegments([1, 109], true);

    const handleComplete = () => {
      // Added a pause before looping
      setTimeout(() => {
        animation.playSegments([35, 109], true); //second part
      }, 1000); 
    };

    animation.addEventListener('complete', handleComplete);

    return () => {
      animation.removeEventListener('complete', handleComplete);
      animation.destroy(); // Clean up on unmount
    };
  }, []);

  return <div ref={containerRef} />;
};

export default StampAnimation;
