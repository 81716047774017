import React from "react";
const Footer = ({style}) => {
  return (
    <footer
      id="footer"
      style={{
        position: "absolute",
        width: "100%",
        bottom: "2%",
        left: "50%",
        transform: "translate(-50%, 50%)",
        zIndex: 3,
        ...style,
      }}
    >
      <p
        style={{
          fontSize: "0.5rem",
          ...style,
        }}
      >
        &reg; ALL RIGHTS RESERVED BY COACH. POWERED BY{" "}
        <span
          style={{
            textDecoration: "underline",
            pointerEvents: "auto",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("https://www.instagram.com/conten.tech/", "_blank");
          }}
        >
          CONTEN.T
        </span>
      </p>
    </footer>
  );
};

export default Footer;
