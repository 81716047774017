import { Button, Wraper, TracingButton } from "./components";
import { useNavigate } from "react-router-dom";
import { useSuperfan } from "../superfancontext";
import { useEffect } from "react";
const Index = () => {
  const navigate = useNavigate();
  const { user, startSignIn } = useSuperfan();

  return (
    <Wraper>
      <div className="block" style={{ height: "5svh" }} />

      <div className="imgContainer" style={{ width: "35%" }}>
        <img
          style={{
            width: "100%",
          }}
          src="/webp/logo.webp"
          alt="coachLogo"
        />
      </div>

      <div className="block" style={{ height: "2svh" }} />

      <div
        className="imgContainer"
        style={{ height: "40%", width: "fit-content" }}
      >
        <img
          src="/gif/Stamp_Turn_3.gif"
          alt="stamp"
          style={{
            height: "100%",
          }}
        />
      </div>
      <div className="block" style={{ height: "4svh" }} />
      <h1>GAME ON</h1>

      <div className="block" style={{ height: "2svh" }} />

      <div
        style={{
          width: "80%",
          margin: "0 auto",
        }}
      >
        <p>
          Tap the stamps to the beat of the music <br />
          (warning: they're hot off the press).
        </p>
        <div className="block" style={{ height: "1svh" }} />
        <p>The top scorer will win exclusive prizes, including a Coach bag.</p>
      </div>

      <div className="block" style={{ height: "8%" }} />

      <TracingButton id={"GameLandingPlay"}>
        <Button
          style={{
            position: "relative",
            zIndex: "2",
          }}
          onClick={() => {
            localStorage.setItem(
              "fullUrl",
              window.location.origin + "/songSelection"
            );
            if (user?.isAnonymous) {
              startSignIn({
                to: "/songSelection",
                callBack: () => {
                  console.log("callback");
                },
              });
            } else {
              navigate("/songSelection");
            }
          }}
        >
          PLAY
        </Button>
      </TracingButton>

      <img
        src="/webp/tile.webp"
        alt="coachLogo"
        style={{
          position: "absolute",
          width: "35svw",
          right: "15%",
          bottom: "5%",
          transform: "translate(50%, 50%)",
          zIndex: 1,
        }}
      />
    </Wraper>
  );
};

export default Index;
