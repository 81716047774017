import { Button, Wraper, WraperWhite, TracingButton } from "./components";
import { Page1 } from ".";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Index = () => {
  const navigate = useNavigate();

  let getScreenRatio = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let ratio = h / w;
    return ratio;
  };

  useEffect(() => {
    console.log(getScreenRatio());
  }, []);

  useEffect(() => {
    // Get the theme-color meta tag or create it if it doesn't exist
    let themeMetaTag = document.querySelector('meta[name="theme-color"]');
    if (!themeMetaTag) {
      themeMetaTag = document.createElement("meta");
      themeMetaTag.setAttribute("name", "theme-color");
      document.head.appendChild(themeMetaTag);
    }

    // Save the original theme color and set the new one
    const originalThemeColor = themeMetaTag.getAttribute("content");

    document.body.style.backgroundColor = "#ffffff"; // Change body background color

    // Remove and re-add meta tag to force change on iPhone
    document.head.removeChild(themeMetaTag);
    themeMetaTag.setAttribute("content", "white"); // Replace with desired color
    document.head.appendChild(themeMetaTag);

    // Revert to the original theme color when unmounting
    return () => {
      themeMetaTag.setAttribute("content", originalThemeColor);
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
    };
  }, []);

  return (
    <WraperWhite
      style={{
        backgroundColor: "white",
      }}
    >
      <div className="block" style={{ height: "5svh" }} />
      <div
        className="imgContainer"
        style={{
          width: "35%",
        }}
      >
        <img
          src="/webp/logo.webp"
          alt="coachLogo"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className="block" style={{ height: "1svh" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontFamily: "Helvetica",
            fontSize: "1.5rem",
            fontWeight: "800",
          }}
        >
          WELCOME, PRESS PLAY
        </h1>
        <div className="block" style={{ height: "1svh" }} />
        <p>Coach Digital Experiences Hub</p>
      </div>
      <div className="block" style={{ height: "3svh" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        {/* <TracingButton id={"EventSignUp"}>
          <Button
            style={{
              backgroundColor: "white",
              backgroundImage: "url(webp/btn2.webp)",
            }}
            onClick={() => {
              navigate("/rsvp");
            }}
          >
            EVENT SIGN UP
          </Button>
        </TracingButton> */}

        <TracingButton id={"PlayTheGame"}>
          <Button
            style={{
              height: "2.75rem",
              width: "11rem",
              marginTop: "1rem",
              backgroundColor: "white",
              backgroundImage: "url(webp/btn2.webp)",
            }}
            onClick={() => {
              navigate("/gameLanding");
            }}
          >
            PLAY THE GAME
          </Button>
        </TracingButton>
      </div>
      <div className="block" style={{ height: "5svh" }} />
      <div
        style={{
          backgroundColor: "#f4e3c9",
          width: "calc(100% - 4rem)",
          margin: "0 auto",
          position: "absolute",
          bottom: "8%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <div className="block" style={{ height: "5svh" }} />
        <div
          className="imgContainer"
          style={{
            position: "relative",
            margin: "auto",
            height: getScreenRatio() > 1.7 ? "30svh" : "26svh",
          }}
        >
          <img src="/webp/stamp.webp" alt="coachLogo" />
        </div>

        <div className="block" style={{ height: "2svh" }} />
        <h1
          style={{
            fontSize: "4.5svh",
          }}
        >
          NOW ON PRESS
        </h1>
        <div className="block" style={{ height: "3svh" }} />
        <div
          style={{
            width: "80%",
            margin: "0 auto",
          }}
        >
          <p>
            Leather craftsmanship meets printing artistry at Coach Play APW, our
            newest immersive concept store in Malaysia.
          </p>
          <div className="block" style={{ height: "4svh" }} />
        </div>
      </div>
      <Footer />
    </WraperWhite>
  );
};

export default Index;
