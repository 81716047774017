import React, { useState, useEffect } from "react";
import { Wraper } from ".";
import StampAnimation from "./StampAnimation2";
import { useSuperfan } from "../../superfancontext";

const LoadingBar = ({ unityProgress }) => {
  const [progress, setProgress] = useState(0);
  const { userMetaData } = useSuperfan();

  const convertProgress = (progress) => {
    if (progress >= 1) {
      let doc = document.getElementById("loadingContainer");
      let unity = document.getElementById("unity");
      setTimeout(() => {
        doc.style.opacity = 0;
        doc.style.pointerEvents = "none";
        unity.style.opacity = 1;  
        unity.style.pointerEvents = "all";
      }, 2000);
    }
    return Math.round(progress * 300);
  };

  const toPercentages = (progress) => {
    return Math.round(progress * 100);
  }

  return (
    <section id="loadingContainer">
      <div className="block" style={{ height: "5svh" }} />
      <div
        style={{
          height: "2.5svh",
          margin: "0 auto",
        }}
        className="imgContainer"
      >
        <img
          src="/webp/logo.webp"
          alt="coachLogo"
        />
      </div>
      <div className="block" style={{ height: "25svh" }} />
      <div
        style={{
          height: "20svh",
          width: "fit-content",
          transform: "rotate(10deg)",
          margin: "0 auto",
        }}
        className="floating"
      >
        <img src="/webp/stamp.webp" alt="stamp" style={{ 
            height: "100%", 
          }} />
      </div>
      <div className="block" style={{ height: "2svh" }} />
      <p
        style={{
          fontSize: "0.8rem",
        }}
      >
        <span
        style={{fontWeight: "bold"}}
        >Tips:</span> Turn on sound to play!
      </p>
      <div className="block" style={{ height: "2svh" }} />
      <div
        style={{
          width: "70svw",
          margin: "0 auto",
          textAlign: "center",
          maxWidth: "15rem",
        }}
        className="svgContainer"
      >
        <svg
          viewBox="0 0 300 40"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "70%",
          }}
        >
          <defs>
            <mask id="myMask">
              <rect
                x="0"
                y="0"
                width="296"
                height="36"
                fill="white"
                rx="17"
                ry="17"
              />
            </mask>
          </defs>

          <rect
            x="0"
            y="0"
            width="300"
            height="36"
            fill="black"
            rx="17"
            ry="17"
          />
          <rect
            x="3"
            y="3"
            width={convertProgress(unityProgress)}
            height="30"
            fill="#f2a900"
            rx="15"
            ry="15"
            id="progressBar"
            mask="url(#myMask)"
          />
        </svg>
        <p>{toPercentages(unityProgress)}%</p>
      </div>
      <img
        src="/webp/tile.webp"
        alt="coachLogo"
        style={{
          position: "absolute",
          width: "20svh",
          right: "2%",
          bottom: "2%",
          zIndex: "2",
        }}
      />
    </section>
  );
};

export default LoadingBar;
