import PropTypes from "prop-types";

const Leaderboard = ({ data }) => {
  return (
    <div
    style={{
      width: "100%",
      minHeight:"23rem",
    }}
    >
      <table className="table"
        style={{
          margin: "0 auto",
        }}
      >
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}
            >
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Leaderboard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Leaderboard;
