import { Fragment, useEffect, useState } from "react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { httpsCallable } from "firebase/functions";
import { useSuperfan } from "../../superfancontext";
import LoadingBar from "./LoadingBar";

function UnityComponent({ playType }) {
  const { unityProvider, sendMessage, unload, isLoaded, loadingProgression } =
    useUnityContext({
      loaderUrl: "../unity/Build/Test1.loader.js",
      dataUrl: "../unity/Build/Test1.data",
      frameworkUrl: "../unity/Build/Test1.framework.js",
      codeUrl: "../unity/Build/Test1.wasm",
    });

  const [score, setScore] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { updatescore, functions, user, collection } = useSuperfan();
  const tempReceivedScore = localStorage.getItem("tempReceivedScore");


  useEffect(() => {
    if (playType) {
      const storedSongType = localStorage.getItem("selectedSongType");
      const songType = playType || storedSongType || 1; // Default to 1 if no songType is passed
      console.log("Song type selected:", songType); // Debugging line
      sendMessage("GameManager", "SetSongType", songType);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          sendMessage("GameManager", "PauseGame", storedSongType);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        if (typeof unload === "function") {
          unload().catch((error) =>
            console.error("Error during Unity unload:", error)
          );
        }
      };
    }
    else{
      navigate("/songSelection");
    }
  }, [sendMessage, unload, playType, navigate]);

  function handleClickGetScore() {
    sendMessage("GameManager", "SendTotalScoreToJavaScript");

  }

  useEffect(() => {
    window.ReceiveTotalScore = function (receivedScore) {
      if (user?.uid && receivedScore) {
        receivedScore = receivedScore < 0 ? 0 : receivedScore;
  
        localStorage.setItem("tempReceivedScore", receivedScore);
  
        let uid = user?.uid;
        let getLeftInfo = httpsCallable(functions, "getleftinfo");
        getLeftInfo({
          uid: uid,
          collection: collection,
        }).then((res) => {
          const { name } = res?.data;
          updatescore({
            score: receivedScore,
            name: name,
          }).then(() => {
            navigate("/score", { state: { score: tempReceivedScore } });
          });
        });
      }
    };  
  }, [user]);


  return (
    <div
      style={{
        width: "100svw",
        height: "100svh",
        position: "absolute",
        zIndex: -1,
        top: 0,
      }}
    >
      <LoadingBar unityProgress={loadingProgression} />
      <Unity
        id="unity"
        unityProvider={unityProvider}
        devicePixelRatio={window.devicePixelRatio}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
          top: "0",
          left: "0",
          margin: "0 auto",
          opacity: "0",
          transition: "opacity 1s",
        }}
      />

      {/* <button
        style={{ zIndex: 100, position: "absolute", top: "3%", left: "3%" }}
        onClick={handleClickGetScore}
      >
        Get Score
      </button> */}
    </div>
  );
}

export default UnityComponent;
