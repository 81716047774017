import { Button, Wraper, TracingButton } from "./components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Leaderboard from "./components/Leaderboard";
import { useSuperfan } from "../superfancontext";
import Lottie from "react-lottie";
import LoadingAnimation from "./components/lotties/loading.json";

const Index = () => {
  const navigate = useNavigate();
  const { getLeaderboard } = useSuperfan();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const loadingOption = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    getLeaderboard()
      .then((res) => {
        setData(res);
      })
      .finally(() => {
        setLoading(false); // Turn off loading when data is fetched
      });
  }, []);

  return (
    <Wraper>
      <div className="block" style={{ height: "5svh" }} />
      <div
        style={{
          width: "100%",
          height: "20%",
          position: "relative",
          overflow: "visible",
        }}
      >
        <div
          style={{
            height: "2.5svh",
            alignItems: "left",
            width: "90%",
            margin: "0 auto",
          }}
        >
          <img
            src="/webp/logo.webp"
            alt="coachLogo"
            style={{
              height: "100%",
            }}
          />
        </div>
        <div
          style={{
            margin: "0 auto",
            width: "90%",
          }}
        >
          <h1
            style={{
              textAlign: "left",
              marginTop: "2%",
            }}
          >
            LEADERBOARD
          </h1>
          <img
            src="/webp/score.webp"
            alt="coachLogo"
            style={{
              position: "absolute",
              height: "65%",
              top: "-12%",
              right: "5%",
              zIndex: "5",
              transform: "translate(0,-10%) rotate(350deg)",
            }}
          />
          <div className="block" style={{ height: "1svh" }} />
          <p
            style={{
              textAlign: "left",
            }}
          >
            Top scorer will receive exclusive prizes, including a Coach bag.
            Terms and conditions apply.
          </p>
        </div>
      </div>

      {loading ? ( // Display loading message while data is being fetched
        <div
          style={{
            textAlign: "center",
            marginTop: "40%",
            marginBottom: "40%",
          }}
        >
          {/* <p>Loading leaderboard...</p> */}
          <Lottie options={loadingOption} height={100} width={100} />
        </div>
      ) : (
        <Leaderboard data={data} />
      )}

      <div className="block" style={{ height: "3svh" }} />

      <TracingButton id={"Back"}>
        <Button
          onClick={() => {
            navigate("/score");
          }}
          style={{
            marginBottom: "5%",
          }}
        >
          BACK
        </Button>
      </TracingButton>

      <div className="block" style={{ height: "0svh" }} />

      <span
        onClick={() => {
          window.open(window.origin + "/tnc/APW_Terms.pdf", "_blank");
        }}
        style={{
          display: "block",
          fontSize: "0.6em",
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        T&C apply
      </span>
    </Wraper>
  );
};

export default Index;
